// External
import { hasCookie } from 'cookies-next';
// Internal
import {
  FAQ_EXPANDED,
  RECOMMENDATION_CATEGORY_PICKED,
} from '~hooks/useSegment/eventNames';
import { trpc } from '~utils/trpc';
import axios from '~utils/axios/v3';
import apiRoutes from '~utils/apiRoutes';
import { reportError } from '~utils/helpers';
import { fireMetric } from '~utils/metrics';

// Types
import type { ServiceLocationData } from '~server/trpc/router/page/locations/[locationSlug]/[serviceSlug]/types';

// Hooks
import useSegment from '~hooks/useSegment';

const fetchXSRFCookie = async () => {
  if (!hasCookie('XSRF-TOKEN')) {
    try {
      await axios.get(apiRoutes.USER.CURRENT);
    } catch (error) {
      reportError(error);
    }
  }
};

export const useCreateJobDraft = () => {
  const jobDraftMutation = trpc.page.home.createJobDraft.useMutation();

  const createJobDraft = async ({
    locale,
    source,
    v3CategoryId,
    v3TaskTemplateId,
  }: {
    locale: string;
    source: string;
    v3CategoryId?: number;
    v3TaskTemplateId?: number;
  }) => {
    if (v3CategoryId && v3TaskTemplateId) {
      await fetchXSRFCookie();
      return jobDraftMutation.mutateAsync({
        categoryId: v3CategoryId,
        formReferrer: source,
        locale,
        source,
        taskTemplateId: v3TaskTemplateId,
      });
    }
  };

  return createJobDraft;
};

// Queuebus events
export const fireJobDraftCreatedEvent = ({
  eventName,
  jobDraftId,
  locale,
  source,
}: {
  eventName: string;
  jobDraftId: string;
  locale: string;
  source: string;
}) =>
  fireMetric(eventName, {
    form_referral: source,
    job_draft_id: jobDraftId,
    locale,
    source,
  });

// Segment events
export const useSLPSegmentHelper = ({
  locale,
  location,
  pageContext,
  service,
}: {
  locale: string;
  location: ServiceLocationData['location'];
  pageContext: string;
  service?: ServiceLocationData['service'];
}) => {
  const { trackEvent } = useSegment();

  const trackCtaClicked = (triggerLocation: string) => {
    trackEvent({
      name: RECOMMENDATION_CATEGORY_PICKED,
      properties: {
        job_category: service?.name ?? null,
        job_category_id: service?.v3CategoryId ?? null,
        metro: location.name || null,
        metro_id: location.v3MetroId || null,
        page: pageContext,
        page_location_metro_id: location.v3MetroId,
        page_location_name: location.name,
        service_name: service?.name,
        service_v3_task_template_id: service?.v3TaskTemplateId,
        task_template_id: service?.v3TaskTemplateId || null,
        trigger_location: triggerLocation,
        trigger_method: 'cta_button_clicked',
      },
    });
  };

  const trackFAQ = (linkLabel: string) => {
    trackEvent({
      name: FAQ_EXPANDED,
      properties: {
        link_label: linkLabel,
        page: pageContext,
        page_location_metro_id: location.v3MetroId,
        page_location_name: location.name,
        service_name: service?.name,
        service_v3_task_template_id: service?.v3TaskTemplateId,
      },
    });
  };

  return {
    trackCtaClicked,
    trackFAQ,
  };
};
