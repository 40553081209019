// Internal
import { isValidJSON } from './utm';

// Helpers
export const clickIds = [
  'fbclid',
  'gclid',
  'msclkid',
  'pclid',
  'ttclid',
] as const;

export const findClickId = (eventMetadata: { [key: string]: string }) => {
  const clickId = clickIds.reduce((acc, id) => {
    if (eventMetadata[id]) return { ...acc, [id]: eventMetadata[id] };
    return acc;
  }, {});

  // Invalidate the click ID if multiple are found (mainly coming from search)
  if (Object.keys(clickId).length !== 1) return undefined;

  return clickId;
};

export const getClickId = (search: string, eventMetadataCookie?: string) => {
  let obj: { [key: string]: string } = {};

  if (search) {
    const searchParams = Array.from(new URLSearchParams(search).entries());
    obj = Object.fromEntries(
      searchParams.map(([k, v]) => [k.toLowerCase(), v])
    );
  } else if (eventMetadataCookie && isValidJSON(eventMetadataCookie)) {
    obj = JSON.parse(eventMetadataCookie);
  }

  return findClickId(obj);
};
