// External
import Head from 'next/head';
import { useRouter } from 'next/router';

// Internal
import getDomain from '~utils/getDomain';

// Types
import type { Seo } from '~server/types/content';

const SEOHead = ({
  description,
  hreflangs,
  locale,
  noindex,
  ogTags,
  title,
}: Seo & { locale: string }) => {
  const { asPath } = useRouter();
  const { origin, pathname: localePrefix } = getDomain(locale);

  /**
   * Omit all query params from canonical URL
   *
   * NOTE: This is fine for pages that currently use SEOHead, but could be
   *       a problem for dynamic pages reliant on particular query params
   *       for rendering content
   */
  const canonicalUrl = origin + localePrefix + asPath.split('?')[0];

  return (
    <Head>
      {/* Page Title */}
      {title ? <title>{title}</title> : null}

      {/* Canonical URL */}
      <link href={canonicalUrl} rel="canonical" />

      {/* Meta tags */}
      {description ? <meta content={description} name="description" /> : null}

      {/* Noindex */}
      {noindex ? <meta content="noindex" name="robots" /> : null}

      {/* HREFLang tags */}
      {hreflangs?.map((data) => (
        <link
          href={data.href}
          hrefLang={data.hreflang}
          key={data.href}
          rel="alternate"
        />
      ))}

      {/* OG tags */}
      {ogTags?.map((data) => (
        <meta
          content={data.content}
          key={data.property}
          property={data.property}
        />
      ))}
    </Head>
  );
};

export default SEOHead;
