// Internal
import { getCookie } from 'cookies-next';
import MetricsBusService, {
  MetricsEventConfig,
} from '~services/MetricsBusService';
import { guardLocale } from '~lang';

type MarketingParams = {
  gclid?: string;
  ref?: string;
  utm_adgroup?: string;
  utm_adgroupid?: string;
  utm_campaign?: string;
  utm_campaignid?: string;
  utm_category?: string;
  utm_content?: string;
  utm_country?: string;
  utm_creative?: string;
  utm_extensionid?: string;
  utm_locationid?: string;
  utm_marketplace?: string;
  utm_medium?: string;
  utm_metro?: string;
  utm_network?: string;
  utm_source?: string;
  utm_target?: string;
  utm_term?: string;
};

const marketingParamTypes = [
  'gclid',
  'ref',
  'utm_adgroup',
  'utm_adgroupid',
  'utm_campaignid',
  'utm_campaign',
  'utm_category',
  'utm_content',
  'utm_country',
  'utm_creative',
  'utm_locationid',
  'utm_extensionid',
  'utm_marketplace',
  'utm_medium',
  'utm_metro',
  'utm_network',
  'utm_source',
  'utm_target',
  'utm_term',
];

export const marketingData = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const marketingParams: MarketingParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (marketingParamTypes.includes(key)) {
      marketingParams[key as keyof MarketingParams] = value;
    }
  }

  const marketingReferrer = new URLSearchParams(marketingParams).toString();

  return {
    marketing_referrer: marketingReferrer,
    ...marketingParams,
  };
};

export const hasMarketingData = () =>
  marketingData().marketing_referrer.length > 0;

export const isExternal = () => {
  const referrerHostname = document.referrer.split('?')[0];
  return referrerHostname.length > 0 && !referrerHostname.match(/taskrabbit\./);
};

export const fireMetric = (
  key: string,
  additionalParams: {
    categorizer_success?: string;
    category_id?: number;
    form_referral?: string;
    job_draft_id?: string;
    locale: string;
    search_term?: string;
    source?: string;
    task_template_id?: number;
  }
) => {
  const { locale } = additionalParams;
  const guardedLocale = guardLocale(locale);

  const guid = getCookie('uid');
  const session = getCookie('session');
  const timestamp = Date.now();

  let sessionId = window.sessionStorage.getItem('tr_sess_id');
  if (sessionId) {
    sessionId = JSON.parse(sessionId);
  } else {
    const trSessionId = guid + '_' + timestamp;
    window.sessionStorage.setItem('tr_sess_id', JSON.stringify(trSessionId));
    sessionId = trSessionId;
  }

  const location = document.location;

  const data = {
    client_publish_key: key,
    client_width: window.innerWidth,
    epoch_ms: timestamp,
    eventSource: 'bus',
    first_time_user: !session,
    guid,
    hostname: location.hostname,
    logged_in: !!session,
    metric_client: 'web',
    pathname: location.pathname,
    protocol: location.protocol,
    query: location.search,
    referrer: document.referrer,
    session_id: sessionId,
    source_form: 'nextjs',
    target_url: location.href,
    ...additionalParams,
    ...marketingData(),
    locale: guardedLocale,
  };

  MetricsBusService.fire(MetricsEventConfig[key] ?? key, data);
};
