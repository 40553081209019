// External
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';

// Internal
import { parseLocale } from '~utils/helpers';
import {
  eventMetadataKey,
  getSegmentSessionId,
} from '~utils/marketing/eventMetadata';
import { findClickId } from '~utils/marketing/clickId';

// Types
import type { BookDetailsMeta } from '~server/trpc/router/page/book/types';
import type { EventProperties, SegmentEvent } from '@segment/analytics-next';

type Properties = {
  [key: string]: any;
};

interface SegmentAnalyticsEventParameters {
  name: string;
  properties: Properties;
}

interface SegmentAnalyticsPageParameters {
  name: string;
  properties?: Properties;
}

interface SegmentAnalyticsIdentityParameters {
  properties?: Properties;
  user: Partial<Omit<BookDetailsMeta['user'], 'currentClientApplicationId'>>;
}

export const getCampaignParams = () => {
  const eventMetadataCookie = getCookie(eventMetadataKey);

  if (typeof eventMetadataCookie !== 'string') return {};

  try {
    const { utm, ...rest } = JSON.parse(eventMetadataCookie);
    const clickId = findClickId(rest);

    return utm || clickId ? { campaign: { ...utm, ...clickId } } : {};
  } catch (e) {
    return {};
  }
};

const transformIdsToString = (properties?: Properties) => {
  if (!properties) return {};

  const transformed: Properties = {};
  for (const key in properties) {
    if (
      (key === 'id' || key.endsWith('_id')) &&
      typeof properties[key] === 'number'
    ) {
      transformed[key] = String(properties[key]);
    } else {
      transformed[key] = properties[key];
    }
  }
  return transformed;
};

const DEFAULT_HANDLERS = {
  reset: () => {},
  trackEvent: () => {},
  trackIdentity: () => {},
  trackPage: () => {},
};

const trackIdentity = async ({
  user,
  properties,
}: SegmentAnalyticsIdentityParameters) => {
  const {
    email,
    first_name,
    id,
    last_name,
    locale,
    metro_id,
    metro_name,
    zipcode,
  } = user;

  if (!id) {
    // skip identify tracking when the user id is missing
    return null;
  }

  const eventProperties = {
    country_iso_code: parseLocale(locale).country,
    email,
    first_name,
    id,
    is_tasker: user.rabbit,
    last_name,
    metro: metro_name,
    metro_id,
    server_locale: locale,
    zipcode,
    ...properties,
  };

  const transformedProperties = transformIdsToString(eventProperties);
  return globalThis.analytics.identify(
    transformedProperties.id,
    transformedProperties
  );
};

const getCommonProps = (server_locale?: string) => ({
  country_iso_code: parseLocale(server_locale).country,
  segment_session_id: getSegmentSessionId(),
  server_locale: server_locale ?? '',
});

const reset = () => globalThis.analytics.reset();

const getContext = () => {
  const { campaign } = getCampaignParams();
  if (campaign) {
    return { campaign };
  } else {
    return undefined;
  }
};

const useSegment = () => {
  const { locale, pathname } = useRouter();

  // these functions are often dependencies to hooks
  // memoize to keep reference as stable as possible
  const trackEvent = useCallback(
    async ({ name, properties }: SegmentAnalyticsEventParameters) => {
      const context = getContext();
      const transformedProperties = transformIdsToString(properties);
      const args: [string | SegmentEvent, EventProperties] = [
        name,
        {
          ...getCommonProps(locale),
          ...transformedProperties,
          page: properties.page || pathname,
        },
      ];

      if (context) args.push({ context });

      return globalThis.analytics.track(...args);
    },
    [locale, pathname]
  );

  const trackPage = useCallback(
    async ({ name, properties }: SegmentAnalyticsPageParameters) => {
      const context = getContext();
      const transformedProperties = transformIdsToString(properties);
      const args: [string | SegmentEvent, EventProperties] = [
        name,
        {
          ...getCommonProps(locale),
          ...transformedProperties,
        },
      ];

      if (context) args.push({ context });

      return globalThis.analytics.page(...args);
    },
    [locale]
  );

  if (!process.env.NEXT_PUBLIC_SEGMENT_API_KEY) {
    return DEFAULT_HANDLERS;
  }

  return {
    reset,
    trackEvent,
    trackIdentity,
    trackPage,
  };
};

export default useSegment;
