const contentfulImageLoader = ({
  quality,
  src,
  width,
}: {
  quality?: number;
  src: string;
  width: number;
}) => `${src}?w=${width}&q=${quality || 75}&fm=webp`;

export default contentfulImageLoader;
